<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">{{ title }}</h1>
      <card-component class="has-table has-mobile-sort-spaced">
        <div class="columns card" style="padding: 1em">
          <div class="column is-2">
            <b-field label="ບາດ" horizontal>
              <b-input v-model="model.THB" type="number" />
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="ໂດລາ" horizontal>
              <b-input v-model="model.USD" type="number" />
            </b-field>
          </div>
          <div class="column is-2">
            <b-button icon-left="check" class="is-success" @click="save()">
              ເພີ່ມ
            </b-button>
          </div>
        </div>
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          :data="models"
          :current-page="currentPage"
          :pagination-rounded="true"
          :selected.sync="model"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index * currentPage + 1 }}
          </b-table-column>
          <b-table-column
            label="ຈາກວັນທີ"
            field="fromdate"
            sortable
            v-slot="props"
          >
            {{ props.row.fromdate | datetime }}
          </b-table-column>
          <b-table-column
            label="ຫາວັນທີ"
            field="todate"
            sortable
            v-slot="props"
          >
            {{ props.row.todate | datetime }}
          </b-table-column>
          <b-table-column label="ບາດ" field="THB" sortable v-slot="props">
            {{ props.row.THB | formatnumber }}
          </b-table-column>
          <b-table-column label="ໂດລາ" field="USD" sortable v-slot="props">
            {{ props.row.USD | formatnumber }}
          </b-table-column>
          <b-table-column label="ຜູ້ໃຊ້" field="USD" sortable v-slot="props">
            {{ props.row.user.username }}
            {{ props.row.user.firstname }}
            {{ props.row.user.lastname }}
            {{ props.row.user.tel }}
          </b-table-column>
          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import Axios from "axios";
import swal from "sweetalert2";
import { get } from "vuex-pathify";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
export default {
  name: "ExchangeRates",
  components: { CardComponent, EmptyTable },
  computed: {
    exchangerate: get("exchangerate"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ອັດຕາແລກປ່ຽນ ທົ່ວສາຂາ",
      model: { THB: null, USD: null },
      models: [],
      isLoading: false,
    };
  },
  mounted() {
    this.get();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    get() {
      this.isLoading = true;
      Axios.get("exchangerates/gets").then((r) => {
        this.models = r;
        this.model = r[0];
      });
    },
    save() {
      Axios.put("exchangerates", this.model).then((res) => {
        console.log(res);
        this.get();
        swal.fire("ສຳເລັດ", "", "success");
      });
    },
  },
};
</script>
